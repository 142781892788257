export const isFetching = (state: any) => {
    return state.isFetching;
}

export const allOrganisation = (state: any) => {
    return state.allOrganisations
}

export const allLoadDataNewOrgs = (state: any) => {
    return state.allLoadDataNewOrgs
}