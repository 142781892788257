import OrganisationService from '@/services/OrganisationService';

const organisationService = new OrganisationService();

export const getAllOrganisations = async({ commit }: {commit: Function}) => {
    commit('isFetching', true);
    const orgs = await organisationService.getAllOrganisations();
    commit('allOrganisations', orgs);
    commit('isFetching', false);
}

export const createOrganisation = async({ commit }: {commit: Function}, payload: any) => {
    await organisationService.createOrganisation(payload.organisation);
}
export const getAllLoadDataNewOrg = async({ commit }: {commit: Function}) => {    
    const loadOrgs = await organisationService.getAllLoadDataNewOrg();
    commit('allLoadDataNewOrgs', loadOrgs);
}
export const loadDataNewOrg = async({ commit }: {commit: Function}, payload: any) => {
    await organisationService.loadDataNewOrg(payload);
}

export const updateOrganisation = async({ commit }: { commit: Function }, payload: any) => {
    await organisationService.updateOrganisation(payload.orgId, payload.organisation);
}

export const deleteOrganisation = async({ commit }: {commit: Function}, payload: any) => {
    await organisationService.deleteOrganisation(payload);
}
