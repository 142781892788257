import { IHolder, IRegisterHolder } from '@/domain/types'

export const isFetching = (state: any, isFetching: boolean) => {
    state.isFetching = isFetching;
}

export const allHolders = (state: any, holders: IHolder[]) => {
    state.allHolders = holders;
}
export const allMarketHolders = (state: any, marketHolders: IRegisterHolder[]) => {
    state.marketHolders = marketHolders;
}
export const allOrganisationCurrentProducts = (state: any, currentProds: any[]) => {
    state.organisationProducts = currentProds;
}

export const holderSelected = (state: any, holderId: string) => {
    state.holderSelected = holderId;
}
