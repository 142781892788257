import * as actions from './actions';
import * as mutations from './mutations';
import * as getters from './getters';

interface ReportState {
    isFetching: boolean,
    weeklyChanges: any
}

const state = (): ReportState => ({
    isFetching: false,
    weeklyChanges: {}
})

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
