import * as actions from './actions';
import * as mutations from './mutations';
import * as getters from './getters';

const state = () => ({
    isFetching: false,
    roadmaps: [],
})

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations    
}