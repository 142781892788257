import { Credentials } from "@/domain/types/Credentials";
import * as Axios from 'axios';
import store from '@/store';
import { User } from "@/domain/User";
import { CookieService } from "./CookieService";

export default class AuthenticationService {
    private cookieService = new CookieService();

    public async login(credentials: Credentials): Promise<string> {
        try {
            const apiConfig: Axios.AxiosRequestConfig = {
                method: 'post',
                url: `${store.state.apiBase}/public/token`,
                data: {
                    ...credentials
                }
            }
            const res: Axios.AxiosResponse = await Axios.default(apiConfig);

            this.cookieService.setCookie("product-cloud", res.data.refreshToken, 1);

            return res.data.token;    
        } catch (e) {
            const err = e as any;

            if (err.message === 'Network Error') throw Error('Authentication Server Unavailable');
            if (err.response.status === 403) throw Error('Incorrect username or password');
            if (err.response.status === 500) throw Error('Server Error');
            else throw Error(`Unhandled Error: ${err.message}`);
        }
    }

    public async silentLogin(): Promise<string> {
        const refreshToken = this.cookieService.getCookie("product-cloud");

        if (!refreshToken) throw Error('No valid refresh token available');

        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'post',
            url: `${store.state.apiBase}/public/token/refresh`,
            data: {
                refreshToken
            }
        }
        const res: Axios.AxiosResponse = await Axios.default(apiConfig);

        this.cookieService.setCookie("product-cloud", res.data.refreshToken, 1);

        return res.data.token;
    }

    public async logout(): Promise<void> {
        const refreshToken = this.cookieService.getCookie("product-cloud");

        try {
            const apiConfig: Axios.AxiosRequestConfig = {
                method: 'post',
                url: `${store.state.apiBase}/public/token/revoke`,
                data: {
                    refreshToken
                }
            }
            const res: Axios.AxiosResponse = await Axios.default(apiConfig);

            this.cookieService.deleteCookie('product-cloud');

            return res.data.token;    
        } catch (e) {
            const err = e as any;

            if (err.message === 'Network Error') throw Error('Authentication Server Unavailable');
            if (err.response.status === 403) throw Error('Incorrect username or password');
            if (err.response.status === 500) throw Error('Server Error');
            else throw Error(`Unhandled Error: ${err.message}`);
        }
    }

    public async resetPassword(username: string): Promise<string> {
        try {
            const apiConfig: Axios.AxiosRequestConfig = {
                method: 'post',
                url: `${store.state.apiBase}/public/password/reset`,
                data: {
                    email: username
                }
            }
            const res: Axios.AxiosResponse = await Axios.default(apiConfig);

            return res.data.response;    
        } catch (e) {
            const err = e as any;
            if (err.message === 'Network Error') throw Error('Authentication Server Unavailable');
            else throw Error(`Unhandled Error: ${err.message}`);
        }
    }

    public async getUser(): Promise<User> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/private/user`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await Axios.default(apiConfig)
        return res.data as User;
    }
}
