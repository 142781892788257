import { IUser } from "@/domain/types";
import cloneDeep from "lodash.clonedeep";

export const isFetching = (state: any) => {
    return state.isFetching;
}

export const usersWithRolesDeclared = (state: any) => {
    const usersCopy = cloneDeep(state.usersInOrg);
    const userOrgSelected = state.userOrgSelected;
    usersCopy.map((user: IUser) => {
        if (!Object.prototype.hasOwnProperty.call(user.organisations, userOrgSelected)) {
            user.organisations[userOrgSelected] = {
                roles: {}
            }
        }
        Object.keys(user.organisations).map((orgId: string) => {
            if (!Object.prototype.hasOwnProperty.call(user.organisations[orgId].roles, 'read')) user.organisations[orgId].roles.read = false;
            if (!Object.prototype.hasOwnProperty.call(user.organisations[orgId].roles, 'edit')) user.organisations[orgId].roles.edit = false;
            if (!Object.prototype.hasOwnProperty.call(user.organisations[orgId].roles, 'approve')) user.organisations[orgId].roles.approve = false;
            if (!Object.prototype.hasOwnProperty.call(user.organisations[orgId].roles, 'admin')) user.organisations[orgId].roles.admin = false;
            if (!Object.prototype.hasOwnProperty.call(user.organisations[orgId].roles, 'support')) user.organisations[orgId].roles.support = false;
        })
        Object.keys(user.organisations).map((orgId: string) => {
            const unorderedRoles: any = user.organisations[orgId].roles;
            const orderedRoles: any = {}
            Object.keys(unorderedRoles).sort().forEach((key: string) => {
                orderedRoles[key] = unorderedRoles[key];
            })
            user.organisations[orgId].roles = orderedRoles;
        })
    })
    return usersCopy;
}

export const allUsers = (state: any) => {
    return state.allUsers
}
