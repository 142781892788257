<template>
    <header class="header-global">
        <router-link slot="brand" class="navbar-brand" to="/">
            <img src="/img/brand/pc-dark-flat.png" alt="logo" class="nav-brand">
        </router-link>
    </header>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'app-header-simple',
})
</script>
<style>
    .nav-brand {
        width: 300px;
    }
</style>
