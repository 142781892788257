import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap';
import "bootstrap/dist/css/bootstrap.min.css"
import { AuthPlugin } from './auth/auth'
import Notifications from '@kyvg/vue3-notification';


// Import the Auth0 configuration
import { envs } from '../auth_config.json'

const env = process.env.VUE_APP_ENV || 'dev';

console.log(`environment: ${env}`);

store.state.apiBase = (envs as any)[env].apiBase;
const clientId = (envs as any)[env].clientId;
const audience = (envs as any)[env].audience;
const domain = (envs as any)[env].domain;

const app = createApp(App);

// Install the authentication plugin
app.use(AuthPlugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: (appState: any) => {
      router.push(
          appState && appState.targetUrl
              ? appState.targetUrl
              : window.location.pathname
      )
  }
})

app.use(router);
app.use(store);
app.use(Notifications);

app.mount('#app')
