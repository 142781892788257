import * as Axios from 'axios';
import BaseService from './BaseService';
import store from '@/store';
import { ICustomer } from '@/domain/types';

export default class CustomerService extends BaseService {
    public async getAllCustomers(): Promise<ICustomer[]> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/customers`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as ICustomer[];
    }

    public async createCustomer(customer: ICustomer): Promise<ICustomer> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'post',
            url: `${store.state.apiBase}/customers`,
            data: {
                customer
            },
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as ICustomer;
    }

    public async updateCustomer(customerId: string, customer: ICustomer): Promise<void> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'put',
            url: `${store.state.apiBase}/customers/update/${customerId}`,
            data: {
                customer
            },
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data;
    }

    public async deleteCustomer(customerId: string): Promise<void> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'delete',
            url: `${store.state.apiBase}/customers/${customerId}`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data;
    }
}
