import * as actions from './actions';
import * as mutations from './mutations';
import * as getters from './getters';

const state = () => ({
    isFetching: false,
    allOrganisations: [],
    orgSelected: "",
    allLoadDataNewOrgs: []
})

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
