import * as Axios from 'axios';
import BaseService from './BaseService';
import store from '@/store';
import { IOrganisation, ILoadPendingOrg } from '@/domain/types';

export default class OrganisationService extends BaseService {
    public async getAllOrganisations(): Promise<IOrganisation[]> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/organisations`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as IOrganisation[];
    }

    public async createOrganisation(organisation: IOrganisation): Promise<IOrganisation> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'post',
            url: `${store.state.apiBase}/organisations`,
            data: {
                organisation
            },
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as IOrganisation;
    }
    public async getAllLoadDataNewOrg(): Promise<ILoadPendingOrg[]> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/organisations/loadDataNewOrg`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as any;
    }
    public async loadDataNewOrg(newDataInfo: IOrganisation): Promise<any> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'post',
            url: `${store.state.apiBase}/organisations/loadDataNewOrg`,
            data: {
                newDataInfo: newDataInfo
            },
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as IOrganisation;
    }

    public async updateOrganisation(orgId: string, organisation: IOrganisation): Promise<void> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'put',
            url: `${store.state.apiBase}/organisations/update/${orgId}`,
            data: {
                organisation
            },
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data;
    }

    public async deleteOrganisation(orgId: string): Promise<void> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'delete',
            url: `${store.state.apiBase}/organisations/${orgId}`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data;
    }
}
