<template>
    <header class="header-global py-2">
        <div class="nav d-flex justify-content-end">
            <div class="navbar-nav m-2" v-if="!showSecure">
                <div v-if="showSignIn" class="nav-item me-3">
                    <router-link v-if="isLoggedIn" to="/landing" class="btn btn-neutral btn-icon d-flex align-items-center">
                        <LoginOutlined class="me-2"/>
                        <span class="nav-link-inner--text">Enter</span>
                    </router-link>
                    <router-link v-else to="/login" class="btn btn-neutral btn-icon d-flex align-items-center">
                        <LoginOutlined class="me-2"/>
                        <span class="nav-link-inner--text">Sign In</span>
                    </router-link>
                </div>
            </div>
            <div v-if="showSecure" class="navbar-nav me-3">
                <div class="dropright">
                    <button type="button" class="header-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                        <AppstoreOutlined class="features-button"/>
                    </button>
                    <ul class="dropdown-menu">
                        <li><a class="dropdown-item" href="https://www.productcloud.com.au" target="_blank">
                            ProductCloud
                        </a></li>
                        <li>
                            <a class="dropdown-item" href="https://www.productcloud.com.au" target="_blank">
                            ZenDesk Portal
                        </a></li>
                    </ul>
                </div>
            </div>
            <div v-if="showSecure" class="navbar-nav me-4 mt-0.5">
                <div class="dropright">
                    <button type="button" class="header-dropdown user-button user-initial p-2" data-bs-toggle="dropdown" aria-expanded="false">
                        <span class="user-initial">{{ userInitials }}</span>
                    </button>
                    <ul class="dropdown-menu">
                        <li>
                            <router-link v-if="isLoggedIn" to="/logout" class="dropdown-item btn btn-neutral btn-icon d-flex align-items-center">
                                <LogoutOutlined class="me-2"/>
                                <span>Log Out</span>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </header>
</template>
<script lang="ts">
import {defineComponent, computed } from 'vue';
import {
    LoginOutlined,
    LogoutOutlined,
    AppstoreOutlined
} from '@ant-design/icons-vue';
import store from '@/store';

export default defineComponent({
    name: 'app-header',
    components: {
        LoginOutlined,
        LogoutOutlined,
        AppstoreOutlined
    },
    setup() {

        const showSignIn = computed((): Boolean => {
            return store.state.showSignIn
        })

        const showSecure = computed((): Boolean => {
            return store.state.showSecure
        })

        const isLoggedIn = computed((): Boolean => {
            return !store.state.$auth.loading && store.state.$auth.isAuthenticated
        })

        const user:any = computed(() => {
            return store.state.currentUser
        })

        const userInitials = computed((): string => {
            if (user.value) {
                if (user.value.firstName && user.value.lastName) {
                    return user.value.firstName[0] + user.value.lastName[0];
                } else if (user.value.firstName && ! user.value.lastName) {
                    return user.value.firstName
                } else return '??'
            } else {
                return '??';
            }
        });

        return {
            showSignIn,
            showSecure,
            isLoggedIn,
            user,
            userInitials
        }
    }
}) 

</script>
<style lang="scss">
.header-global {
    border-bottom: 1px solid lightgray;
    .navbar {
        padding-top: 0;
        padding-bottom: 0;
    }
    .nav-brand {
        width: 200px;
    }
    .header-dropdown {
        background: transparent;
        border: 0;
        padding: 0;
        line-height: 0;
    }
    .user-button {
        color: #333;
        &:focus, &:active, &:hover {
            color: #333 !important;
            background: transparent;
            border: 0;
        }
        &.dropdown-toggle {
            background: transparent !important;
            background-color: transparent !important;
            border-color: transparent !important;
        }
    }
    .features-button {
        color: #333;
        font-size: 24px;
    }
    .user-initial {
        color: #333;
        box-shadow: none;
        background: white;
        background-color: white;
        &:focus, &:hover {
            box-shadow: none;
        }
    }
}
</style>
