import IndustryService from '@/services/IndustryService';

const industryservice = new IndustryService();

export const getAllHolders = async({ commit }: {commit: Function}) => {
    commit('isFetching', true);
    const holders = await industryservice.getAllHolders();
    // const marketHolders = await industryservice.getMarketHolders();
    commit('allHolders', holders);
    // commit('allMarketHolders', marketHolders);
    commit('isFetching', false);
}
export const getAllMarketHolders = async({ commit }: {commit: Function}) => {
    const marketHolders = await industryservice.getMarketHolders();
    commit('allMarketHolders', marketHolders);
}
export const getOrganisationCurrentProds = async({ commit }: {commit: Function}, payload: any) => {
    const orgProducts = await industryservice.getholderProducts(payload);
    commit('allOrganisationCurrentProducts', orgProducts);
}

export const createHolder = async({ commit }: {commit: Function}, payload: any) => {
    await industryservice.createHolder(payload.holder);
}

export const updateHolder = async({ commit }: { commit: Function }, payload: any) => {
    await industryservice.updateHolder(payload.holderId, payload.holder);
}

export const deleteHolder = async({ commit }: {commit: Function}, payload: any) => {
    await industryservice.deleteHolder(payload);
}
