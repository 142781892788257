import UserService from '@/services/UserService';

const userService = new UserService();

export const getAllUsers = async({ commit }: {commit: Function}) => {
    commit('isFetching', true);
    const users = await userService.getAllUsers();
    commit('allUsers', users);
    commit('isFetching', false);
}

export const getUser = async({ commit }: {commit: Function}, userId: string) => {
    commit('isFetching', true);
    const user = await userService.getUser(userId);
    commit('user', user);
    commit('isFetching', false);
}

export const getAllUsersByOrg = async({ commit }: {commit: Function}, orgId: string) => {
    commit('isFetching', true);
    const users = await userService.getUsers(orgId);
    commit('usersInOrg', users);
    commit('userOrgSelected', orgId);
    commit('isFetching', false);
}

export const createUser = async({ commit }: {commit: Function}, payload: any) => {
    await userService.createUser(payload.user);
}

export const deactivateUser = async({ commit }: {commit: Function}, userId: string) => {
    await userService.deactivateUser(userId);
}

export const saveUser = async({ commit }: { commit: Function }, payload: any) => {
    await userService.saveUser(payload.userId, payload.user);
}
