export class FetchBase {
  public fetchTime: Date = new Date();
  public isFetched: boolean = false;
  public isFetching: boolean = false;

  protected startFetch() {
      this.isFetching = true;
      this.isFetched = false;
  }

  protected endFetch(success: boolean) {
      this.isFetching = false;
      this.isFetched = success;
      this.fetchTime = new Date();
  }
}

export function fetchable(
  target: any,
  propertyName: string,
  propertyDesciptor: PropertyDescriptor): PropertyDescriptor {
  const method = propertyDesciptor.value;

  propertyDesciptor.value = function(...args: any[]) {
      target.startFetch.apply(this);

      return method.apply(this, args).then((result: any) => {
        target.endFetch.apply(this, [true]);
      }, () => {
        target.endFetch.apply(this, [false]);
      });
  }
  return propertyDesciptor;
};
