import { IUser } from '@/domain/types'

export const isFetching = (state: any, isFetching: boolean) => {
    state.isFetching = isFetching;
}

export const allUsers = (state: any, users: IUser[]) => {
    state.allUsers = users;
}

export const user = (state: any, user: IUser) => {
    state.user = user;
}

export const usersInOrg = (state: any, users: IUser[]) => {
    state.usersInOrg = users;
}

export const userOrgSelected = (state: any, orgId: string) => {
    state.userOrgSelected = orgId;
}
