import { ICustomer } from '@/domain/types'

export const isFetching = (state: any, isFetching: boolean) => {
    state.isFetching = isFetching;
}

export const allCustomers = (state: any, customers: ICustomer[]) => {
    state.allCustomers = customers;
}

export const customerSelected = (state: any, customerId: string) => {
    state.customerSelected = customerId;
}
