<template>
    <footer class="footer has-cards">
        <div class="container-fluid">
            <div class="row">
                <div class="col">
                    <div class="copyright">
                        &copy; {{year}}
                        <a href="https://www.redcrew.com.au" target="_blank" rel="noopener">RedCrew</a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>
<script lang="ts">
import {defineComponent, computed } from 'vue';

export default defineComponent({
    name: 'app-footer',
    setup(){
        const year = computed(() => {
            return new Date().getFullYear()
        });

        return {year}
    }
})
</script>
<style lang="scss" scoped>
.footer {
    border-top: 1px solid lightgray;
}
.copyright {
    font-size: 12px;
    text-align: center;
    a {
        color: #333;
    }
}
</style>
