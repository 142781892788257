import Vue from 'vue'
import { createStore } from 'vuex'

// Modules
import user from './user'
import organisation from './organisation'
import customer from './customer'
import industry from './industry'
import reports from './reports'
import roadmaps from './roadmaps'


import { IFetchable, IUserProfile } from '@/domain/types';
import { UserProfile } from '@/domain/UserProfile';

class State {
    // Sign in params
    public showSecure: boolean = false
    public showSignIn: boolean = true
    public postLoginRoute: string = '/landing'
    public $auth: any
    public apiBase: string = ''

    public currentUser: IUserProfile & IFetchable<IUserProfile> = new UserProfile()

    public newCreateHolder: any = {}
}

const store = createStore({
    state: new State(),

    mutations: {
        logout(state:State) {
            state.currentUser = new UserProfile()
        },
        loginStart(state:State) {
        },
        loginComplete(state:State) {
            state.currentUser = new UserProfile()
            if (state.$auth.isAuthenticated) {
                state.currentUser.fetch();
            }
        }
    },
    actions: {
    },
    modules: {
        user,
        organisation,
        customer,
        industry,
        reports,
        roadmaps
    }
})

export default store
