export class CookieService {
    public setCookie(name: string, value: string, expireDays: number): void {
      const date = new Date();
      date.setTime(date.getTime() + (expireDays * 24 * 60 * 60 * 1000));
      const expires = "expires=" + date.toUTCString();
      document.cookie = name + "=" + value + ";" + expires + ";path=/";
    }
  
    public getCookie(name: string): string | undefined {
      const cookieName = name + "=";
      const decodedCookie = decodeURIComponent(document.cookie);
      const cookieArray = decodedCookie.split(';');

      for (const element of cookieArray) {
        let cookie = element;
        
        while (cookie.charAt(0) === ' ') {
          cookie = cookie.substring(1);
        }
        
        if (cookie.indexOf(cookieName) === 0) {
          return cookie.substring(cookieName.length, cookie.length);
        }
      }

      return undefined;
    }
  
    public deleteCookie(name: string): void {
      document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }
  }
