import { App as Application, createApp} from 'vue';
import store from '@/store';
import { Credentials } from '@/domain/types/Credentials';
import AuthenticationService from '@/services/AuthenticationService';

/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK = (appState: any) => {
    window.history.replaceState({}, document.title, window.location.pathname)
}

let instance: any

const authenticationService = new AuthenticationService();

/** Returns the current instance */
export const getInstance = (): any => { return instance }

/** Creates an instance of the Auth library. If one has already been created, it returns that instance */
export const useAuth = (app: Application,{ onRedirectCallback = DEFAULT_REDIRECT_CALLBACK }: any) => {
    if (instance) return instance
    // The 'instance' is simply a Vue object
    instance = createApp({
        data() {
            return {
                loading: true,
                isAuthenticated: false,
                token: '',
                user: {},
                error: ''
            }
        },
        methods: {
            async handleDeepLink(): Promise<any> {
                this.loading = true
                try {
                    this.token = await authenticationService.silentLogin();
                    this.user = await authenticationService.getUser();
                    this.isAuthenticated = true;
                } catch (e) {
                    const err = e as any;
                    this.error = err
                } finally {
                    this.loading = false
                }
            },
            async login(credentials: Credentials, targetURL?: string) {
                this.token = await authenticationService.login(credentials);
                this.user = await authenticationService.getUser();
                this.isAuthenticated = true;

                store.commit('loginComplete');

                onRedirectCallback({ targetUrl: targetURL || '/landing' });
            },
            /** Logs the user out and removes their session on the authorization server */
            async logout(o: any) {
                await authenticationService.logout();
                this.token = "";
                this.user = {};
                this.isAuthenticated = false;

                store.commit('logout');

                onRedirectCallback({ targetUrl: '/' });
            },
            async loadingPromise (): Promise<void> {
                while (store.state.$auth.loading) {
                  await new Promise(resolve => setTimeout(resolve, 10));
                }
                return;
            }
        },
        /** Use this lifecycle method to instantiate the SDK client */
        async created() {
            try {
                this.isAuthenticated = false;
                this.user = {};
                this.loading = false;

                await this.handleDeepLink();
            } catch (e) {
                const err = e as any;
                this.error = err
            } finally {
                store.commit('loginComplete');
            }
        }
    }).mount('#auth-component');

    return instance;
}

// Create a simple Vue plugin to expose the wrapper object throughout the application
export function AuthPlugin(app: Application, options?: any): void {
    try {
        store.state.$auth = useAuth(app,options);
    } catch (e) {
        console.log(`Error initialzing security handler: ${e}`);
    }
}